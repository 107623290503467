import { SxProps, Theme } from '@mui/material';

export const globalEspotContainerSX: SxProps<Theme> = () => ({
	'& p': {
		marginBottom: '1rem',
	},
	'& table': {
		borderCollapse: 'collapse',
		borderColor: 'currentColor',
		borderSpacing: '0',
		border: '1px solid #EBEEEF',
		width: '100%',
		'& thead': {
			backgroundColor: '#F7F7F7',
		},
		'& td, th': {
			borderBottom: '1px solid #EBEEEF',
			textAlign: 'left',
			padding: '1rem',
		},
		'& tr:last-child td, & tr:last-child th': {
			border: '0',
		},
	},
	'& .MuiTypography-root.MuiTypography-inherit.MuiLink-root': {
		fontWeight: '600',
	},
	'& .MuiTypography-root.MuiTypography-contrastText.MuiLink-root': {
		fontWeight: '600',
	},
});

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Layout } from '@/data/types/Layout';

export const getCheckOutShippingPage = (): Layout => ({
	name: 'DoubleStack',
	slots: {
		header: [{ name: 'MinimizedHeader', id: 'check-out-header' }],
		first: [],
		second: [{ name: 'CheckOutShipping', id: 'check-out-shipping' }],
		footer: [{ name: 'CheckOutFooter', id: 'check-out-footer' }],
	},
});

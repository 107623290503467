/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme, AccordionProps, Interpolation } from '@mui/material';
import { breakpoints } from '@/styles/JJKeller/breakpoints';
export const AccordionVariants: {
	props: Partial<AccordionProps>;
	style: Interpolation<{ theme: Theme }>;
}[] = [
	{
		props: { variant: 'mobileonly-collapse' },
		style: {
			border: 'none',
			'&.MuiAccordion-root': {
				border: 'none',
			},
			':first-of-type': {
				'.MuiAccordionSummary-root': {
					borderTopLeftRadius: '0',
					borderTopRightRadius: '0',
				},
			},
			':last-of-type': {
				borderBottom: '1px solid #BDC5CA',
				borderBottomLeftRadius: '0',
				borderBottomRightRadius: '0',
				'.MuiAccordionSummary-root': {
					borderBottomLeftRadius: '0',
					borderBottomRightRadius: '0',
				},
			},
			'.MuiAccordionSummary-root': {
				borderLeft: 'none',
				borderRight: 'none',
				borderColor: '#BDC5CA',
				borderBottom: 'none',
			},
			'.MuiAccordionDetails-root': {
				borderLeft: 'none',
				borderRight: 'none',
			},
			[`@media (min-width:${breakpoints.breakpoints?.values?.md}px)`]: {
				'.MuiCollapse-root': {
					height: 'auto !important',
					visibility: 'visible',
				},
				'.MuiAccordionSummary-root ': {
					'.Mui-expanded .expand-less': {
						display: 'none',
					},
					'.expand-more': {
						display: 'none',
					},
					'&:hover:not(.Mui-disabled)': {
						cursor: 'auto',
					},
				},
			},
		},
	},
	{
		props: { variant: 'no-border' },
		style: {
			border: 'none',
			'&.MuiAccordion-root': {
				border: 'none',
			},
			':first-of-type': {
				'.MuiAccordionSummary-root': {
					borderTopLeftRadius: '0',
					borderTopRightRadius: '0',
				},
			},
			':last-of-type': {
				borderBottom: 'none',
				borderBottomLeftRadius: '0',
				borderBottomRightRadius: '0',
				'.MuiAccordionSummary-root': {
					borderBottomLeftRadius: '0',
					borderBottomRightRadius: '0',
				},
			},
			'.MuiAccordionSummary-root': {
				borderLeft: 'none',
				borderRight: 'none',
				borderBottom: 'none',
				borderTop: 'none',
				'.Mui-expanded .expand-less': {
					display: 'none',
				},
				'.expand-more': {
					display: 'none',
				},
			},
			'.MuiAccordionDetails-root': {
				borderLeft: 'none',
				borderRight: 'none',
			},
		},
	},
];

declare module '@mui/material/Paper' {
	interface PaperPropsVariantOverrides {
		'mobileonly-collapse': true;
		'no-border': true;
	}
}

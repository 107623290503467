import { Layout } from '@/data/types/Layout';

export const getNewsArticlePage = (): Layout => ({
	name: 'DoubleStack',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [{ name: 'StaticBreadcrumbTrail', id: 'news-article' }],
		second: [{ name: 'NewsArticle', id: 'news-article' }],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});

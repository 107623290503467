/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const ACCOUNT = 'ACCOUNT';
export const HYPHEN = '-';
export const ORDER_ID = 'orderid';
export const BASE_PATH = process.env.basePath ?? '';
export const MOCK_MODE = process.env.USE_MOCK === 'true';
export const HEADER_HOST_KEY = 'host';
export const HCL_DOT_IMAGE_PATH = 'hcl.imagePath';
export const SKIP_ERROR_LOGGING = {
	404: true,
};
export const G_MAPS_Z_INDEX = 1000;

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Layout } from '@/data/types/Layout';
import { FC } from 'react';
import { TripleStackPdp } from '@/components/layouts/TripleStackPdp';
import { AsideExtendedRight } from '@/components/layouts/AsideExtendedRight';
import { AsideMobileColumnReverse } from '@/components/layouts/AsideMobileColumnReverse';
import { AsideExtendedSearchPlp } from '@/components/layouts/AsideExtendedSearchPlp';

export const layoutManifestCustom: {
	[key: string]: FC<{ layout: Layout }>;
} = { TripleStackPdp, AsideMobileColumnReverse, AsideExtendedRight, AsideExtendedSearchPlp };

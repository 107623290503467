/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';
import { Link, Button, SxProps, Theme, useTheme, useMediaQuery } from '@mui/material';
import { ComponentProps, FC, forwardRef } from 'react';
import { Switch } from '@/utils/switch';
import { ImageProps } from 'next/image';
import { constructNextUrl, constructNextUrlForParsedHtml, useNextRouter } from '@/data/Content/_NextRouter';
import { MaterialImage } from '@/components/blocks/MaterialImage';
import { useSettings } from '@/data/Settings';
import { UrlObject } from 'url';
import { scrollToTarget } from '@/utils/scrollToTarget';
import { CartLink } from '@/components/blocks/AddToCartLinks';
import { getAddToCartParams } from '@/utils/getAddToCartParams';

type LinkablePropsCommon = {
	href?: LinkProps['href'];
	sx?: SxProps<Theme>;
	[key: string]: unknown;
};

type LinkablePropsText = {
	type?: 'link' | 'button' | 'inline';
	children?: JSX.Element | JSX.Element[] | string;
} & LinkablePropsCommon;

type LinkablePropsImage = {
	type: 'image';
} & LinkablePropsCommon &
	ImageProps;

type LinkableProps = LinkablePropsText | LinkablePropsImage;

/**
 * Used to make any component linkable.
 * `legacyBehavior` will potentially be removed in future release. Try to use with
 * `legacyBehavior={false}`
 */
export const LinkWrap: FC<
	Omit<ComponentProps<typeof NextLink>, 'href'> & { href?: string | UrlObject }
> = ({ href, children, ...props }) => {
	const router = useNextRouter();
	const {
		settings: { storeToken },
	} = useSettings();
	const _href = constructNextUrl(router.asPath, href, storeToken);
	return _href ? (
		<NextLink href={_href} as={getUrl(_href as UrlObject)} passHref legacyBehavior {...props}>
			{children}
		</NextLink>
	) : (
		<>{children}</>
	);
};
const getUrl = (url: UrlObject) => {
	let searchParamsStr = '';
	if (typeof url !== 'string' && url?.query) {
		const searchParamsArr = Object.entries(url?.query);
		if (searchParamsArr.length > 0) {
			searchParamsStr = '?';
			searchParamsArr.forEach((param, ind) => {
				searchParamsStr = searchParamsStr.concat(`${param[0]}=${param[1]}${url?.query && ind === searchParamsArr.length - 1 ? '' : '&'}`);
			});
		}
		return `${url.pathname}${searchParamsStr !== '' ? searchParamsStr : ''}` || '';
	} else {
		return '';
	}
};

const getHashValue = (url: UrlObject) => {
	if (typeof url !== 'string' && url?.pathname) {
		if (url?.pathname !== '' && url?.pathname?.indexOf('#') !== -1) {
			return url?.pathname?.split('#')[1];
		}
	}
};


export const ParseHtmlLinkWrap: FC<
	Omit<ComponentProps<typeof NextLink>, 'href'> & { href?: string | UrlObject }
> = ({ href, children, ...props }) => {
	const {
		settings: { storeToken },
	} = useSettings();
	const _href = constructNextUrlForParsedHtml(href, storeToken);
	// for links with hash value in path
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const urlContainHash = _href ? getHashValue(_href as UrlObject) : '';

	return _href ?
		isMobile && urlContainHash ? (
			<Link component="button" onClick={() => scrollToTarget(urlContainHash, isMobile)} color='inherit'>{children}</Link>
		) : (
			<NextLink href={_href} as={getUrl(_href as UrlObject)} passHref legacyBehavior {...props}>
				{children}
			</NextLink>
		) : (
			<>{children}</>
		);
};
/**
 * Used for general linking, where children can be a simple string label.
 */
export const Linkable: FC<LinkableProps> = forwardRef<HTMLAnchorElement, any>(
	({ type = 'link', href, children, sx, alt = '', ...props }, ref) =>
		Switch(type)
			.case('link', () => (
				<LinkWrap href={href}>
					{href ? (
						<Link ref={ref} {...props} sx={sx} tabIndex={0}>
							{children}
						</Link>
					) : (
						children
					)}
				</LinkWrap>
			))
			.case('button', () => (
				<LinkWrap href={href}>
					<Button ref={ref} component="a" {...props} sx={sx} tabIndex={0}>
						{children}
					</Button>
				</LinkWrap>
			))
			.case('inline', () => (
				<LinkWrap href={href}>
					<Button ref={ref} component="a" {...props} sx={sx} variant="inline" tabIndex={0}>
						{children}
					</Button>
				</LinkWrap>
			))
			.case('image', () => (
				<LinkWrap href={href}>
					<Link sx={sx} ref={ref} tabIndex={0}>
						<MaterialImage alt={alt} {...props} />
					</Link>
				</LinkWrap>
			))
			.defaultTo(() => null)
);

export const parseHtmlLinkable: FC<LinkableProps> = forwardRef<HTMLAnchorElement, any>(
	({ type = 'link', href, children, sx, alt = '', ...props }, ref) => {
		if ((typeof href === 'string' || href instanceof String) && href.includes('/add-to-cart')) {
			return (<CartLink {...props} partNumber={getAddToCartParams(href as string).partNumber} quantity={getAddToCartParams(href as string).quantity} sx={sx}>{children}</CartLink>);
		}
		if ((typeof href === 'string' || href instanceof String) && href.includes('/adv-trial')) {
			return (<Link component="button" id="buy-adv-trial" tabIndex={0} sx={sx}>{children}</Link>);
		}
		return (
			Switch(type)
				.case('link', () => (
					<ParseHtmlLinkWrap href={href}>
						{href ? (
							<Link ref={ref} {...props} sx={sx} tabIndex={0}>
								{children}
							</Link>
						) : (
							children
						)}
					</ParseHtmlLinkWrap>
				))
				.case('button', () => (
					<ParseHtmlLinkWrap href={href}>
						<Button ref={ref} component="a" {...props} sx={sx} tabIndex={0}>
							{children}
						</Button>
					</ParseHtmlLinkWrap>
				))
				.case('inline', () => (
					<ParseHtmlLinkWrap href={href}>
						<Button ref={ref} component="a" {...props} sx={sx} variant="inline" tabIndex={0}>
							{children}
						</Button>
					</ParseHtmlLinkWrap>
				))
				.case('image', () => (
					<ParseHtmlLinkWrap href={href}>
						<Link sx={sx} ref={ref} tabIndex={0}>
							<MaterialImage alt={alt} {...props} />
						</Link>
					</ParseHtmlLinkWrap>
				))
				.defaultTo(() => null));
	}
);

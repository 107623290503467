/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { JJKellerTheme } from '@/styles/JJKeller/theme';

import { SxProps, Theme } from '@mui/material';

export type ThemeManifestTheme = {
	inheritFrom?: string;
	components?: any[];
	additives?: { [key: string]: SxProps | ((theme: Theme) => SxProps) };
};

export type ThemeManifest = {
	defaultTheme?: string;
	themes: {
		[key: string]: ThemeManifestTheme;
	};
};

export const themeManifestCustom: ThemeManifest = {
	defaultTheme: 'JJKellerTheme',
	themes: { JJKellerTheme },
};

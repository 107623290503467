import { Layout } from '@/data/types/Layout';

export const getRequisitionListsPage = (): Layout => ({
	name: 'AsideMobileColumnReverse',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [{ name: 'StaticBreadcrumbTrail', id: 'requisition-lists' }],
		aside: [{ name: 'AccountSidebar', id: 'side-bar' }],
		second: [
			{ name: 'RequisitionLists', id: 'requisition-lists' },
			{ name: 'RequisitionListsTable', id: 'requisition-lists-table' },
		],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Layout } from '@/data/types/Layout';

export const getAccessVideoPage = (): Layout => ({
	name: 'DoubleStack',
	slots: {
		header: [{ name: 'MinimizedHeader', id: 'av-header' }],
		first: [],
		second: [{ name: 'AccessVideo', id: 'access-video' }],
		footer: [{name: 'AvFooter', id: 'avfooter'}],
	},
});

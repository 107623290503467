import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { FC } from 'react';
import { globalEspotContainerSX } from '@/components/content/EMarketingSpot/styles/globalEspotContainer';
import { renderContent } from '@/utils/renderContent';
import { Stack, Typography } from '@mui/material';

export const AccountDashboardEspot: FC<{ espotName: string }> = ({ espotName }) => {
	const { data: espotData } = useContentRecommendation(espotName);

	return (
		<>
			{espotData && Array.isArray(espotData) ? (
				<Stack component="div" sx={globalEspotContainerSX}>
					<Typography variant="body3Bold" sx={{ fontWeight: '400', color: '#4A5055' }}>
						{espotData?.map((content) => renderContent(content))}
					</Typography>
				</Stack>
			) : null}
		</>
	);
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import {
	CONTENT_FORMAT_FILE,
	CONTENT_FORMAT_TEXT,
	CONTENT_FORMAT_WEB,
	CONTENT_MIME_TYPE_IMAGE,
	MARKETING_SPOT_DATA_TYPE,
} from '@/data/constants/marketing';
import { EMarketingSpot, ExtendedBaseMarketingSpotData, ExtendedProcessedContent } from '@/data/types/EMarketingSpotContainer';
import { ProcessedContent } from '@/data/types/Marketing';
// baseMarketingSpotDataType === 'MarketingContent'
// no DX support
/**
 * "contentFormatName": "Text"
 * "contentFormatName": "File"
 *        "contentMimeType": "image", e.g. logo
 *        attachmentAsset list.
 */

type BaseMarketingSpotData = EMarketingSpot;

export const processMarketingContent = (content: BaseMarketingSpotData): ProcessedContent => {
	if (content.baseMarketingSpotDataType === MARKETING_SPOT_DATA_TYPE.CONTENT) {
		const { contentId, contentName } = content;
		if (content.contentFormatName === CONTENT_FORMAT_TEXT) {
			return {
				id: content.baseMarketingSpotActivityID,
				text: content.marketingContentDescription?.at(0)?.marketingText || '',
				contentUrl: content.contentUrl,
				contentId,
				contentName,
			};
		}
		if (
			content.contentFormatName === CONTENT_FORMAT_FILE &&
			content.contentMimeType === CONTENT_MIME_TYPE_IMAGE
		) {
			return {
				id: content.baseMarketingSpotActivityID,
				contentUrl: content.contentUrl,
				asset: content.attachmentAsset?.at(0),
				assetDescription: content.attachmentDescription?.at(0),
				contentId,
				contentName,
			};
		}
	}
	return {};
	// TODO List of attachments.
};

export const preprocessJSON = (str: string) =>
	str.replace(
		/("(\\.|[^"])*"|'(\\.|[^'])*')|(\w+)\s*:/g,
		(all, string, strDouble, strSingle, jsonLabel) => {
			if (jsonLabel) {
				return '"' + jsonLabel + '": ';
			}
			return all;
		}
	);

export const processMarketingPropeties = (
	content: ExtendedBaseMarketingSpotData
): ExtendedProcessedContent => {
	if (content.baseMarketingSpotDataType === MARKETING_SPOT_DATA_TYPE.DM_NVP) {
		const { activityIdentifierId, activityIdentifierName } = content;
		if (content.activityFormat === CONTENT_FORMAT_WEB && content.properties) {
			return {
				id: content.baseMarketingSpotActivityID,
				data: content.properties,
				contentUrl: content.contentUrl,
				activityIdentifierId,
				activityIdentifierName,
			};
		}
	}
	return {};
	// TODO List of attachments.
};

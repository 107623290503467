/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { buttonVariantsStyles } from '@/styles/JJKeller/Button/variantsStyles';
import { buttonVariants } from '@/styles/JJKeller/Button/variants';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';
import { palette } from '@/styles/JJKeller/palette';

export const Button: ThemeOptions = {
	components: {
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
				disableRipple: false,
			},
		},
		MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
			variants: buttonVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						py: 1,
						borderRadius: '4px',
						fontWeight: 600,
                        lineHeight: 1.5,
                        textAlign: 'center',
                        border: '1px solid transparent',
                        '&.MuiButton-sizeSmall': {
                            fontSize: '.875rem',
                        },
                        '&.MuiButton-sizeMedium': {
                            fontSize: '1rem',
                        },
                        '&.MuiButton-sizeLarge': {
                            fontSize: '1.125rem',
                        },
                        '&:focus-visible': {
                            borderWidth: '1px',
                            outlineWidth: '2px',
                            outlineOffset: '0',
						},
						'&.Mui-disabled': {
							color: 'button.disabledText',
                            borderColor: 'transparent',
						},
						'.MuiButton-iconSizeMedium': {
							marginLeft: '4px',
						},
						transition: 'all 0.2s ease-out allow-discrete',
					},
					variants: buttonVariantsStyles,
				}),
				contained: getStyleOverrides({
					styles: {
						backgroundColor: 'button.primary',
						color: 'button.contrastText',
                        '&:focus-visible': {
                            borderColor: 'button.secondary',
                            outlineStyle: 'solid',
                            outlineColor: `${palette.palette.button.focusBorder}`,
						},
						'&:hover': {
							opacity: '0.8',
							backgroundColor: 'button.primary',
						},
						'&.Mui-disabled': {
							backgroundColor: 'button.disabled',
						},
					},
				}),
				outlined: getStyleOverrides({
					styles: {
						backgroundColor: 'button.secondary',
						color: 'button.primary',
						borderColor: 'button.primary',
                        '&:focus-visible': {
                            borderColor: 'button.secondary',
                            outlineStyle: 'solid',
                            outlineColor: `${palette.palette.button.focusBorder}`,
						},
						'&:hover': {
							backgroundColor: 'button.secondaryHover',
							color: 'button.primaryHover',
							borderColor: 'button.primaryHover',
						},
					},
				}),
				text: getStyleOverrides({
					styles: {
						minWidth: 'unset',
						color: 'primary.main',
                        borderColor: 'transparent',
						'&:hover': {
							color: 'primary.dark',
						},
                        '&:focus-visible': {
                            borderColor: 'button.secondary',
                            outlineStyle: 'solid',
                            outlineColor: `${palette.palette.button.focusBorder}`,
						},
					},
				}),
			},
		},
	},
};
declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		inline: true;
		'blue-outlined': true;
		'blue-underline': true;
		'blue-no-outline': true;
		'blue-link': true;
		'blue-contained': true;
		'blue-search': true;
		'burgundy-contained': true;
		'checkout-contained': true;
		'burgundy-outlined': true;
		'burgundy-no-outline': true;
	}
}

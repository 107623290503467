const paths: string[] = ['article', 'topic-headline', 'topic-content-headline'];

export const filterDynamicPaths = (path: string[]) => {
	if (Array.isArray(path)) {
		const page = path?.at(-2);
		if (page && paths.includes(page)) {
			return path.slice(0, -1);
		}
	}
	return path;
};

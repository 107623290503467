/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

/**
 * The default Quantity Unit, as so called unitless unit, opposite to
 * kilogram, meter, liter, pound, foot, quart
 * See [QuantityUnit](https://help.hcltechsw.com/commerce/9.0.0/database/database/qtyunit.html)
 */
export const UNITLESS_UNIT_ONE = 'C62';
export const ORDER_CONFIGS = {
	calculationUsage: '-1,-2,-3,-4,-5,-6,-7',
	calculateOrder: '1',
	noCalculateOrder: '0',
	cart: '1',
	inventoryValidation: true,
	allocate: '***',
	backOrder: '***',
	remerge: '***',
	check: '*n',
};
export const PART_NUMBER_MAX_LENGTH = 70;
export const QUICK_ORDER_INITIAL_VALUES = 4;
export const QUICK_ORDER_MAX_VALUES = 10;
export const ORDER_CONFIGS_NO_SHIPPING = {
	calculationUsage: '-1,-5,-6,-7',
	calculateOrder: '1',
	noCalculateOrder: '0',
	cart: '1',
	inventoryValidation: true,
	allocate: '***',
	backOrder: '***',
	remerge: '***',
	check: '*n',
};
export const SHIP_MODE_CODE_PICKUP = 'PickupInStore';
export const ORDER_HISTORY_TABLE = 'order-history-table';

export const ORDER_STATUS = {
	BackOrdered: 'B',
	Submitted: 'I',
	Approved: 'M',
	NoInventory: 'L',
	ApprovalDenied: 'N',
	PendingOrder: 'P',
	Released: 'R',
	Shipped: 'S',
	PendingApproval: 'W',
	Canceled: 'X',
	Closed: 'D',
	LockedByReturn: 'RTN',
	LockedByAppeasement: 'APP',
};

export const ORDER_HISTORY_REVALIDATION_INTERVAL = 45000;
export const ORDER_HISTORY_PAGE_DESCRIPTION = 'Order_History_Page_Copy';
export const ORDER_HISTORY_NO_RESULTS = 'Order_History_No_Results';
export const CURRENCY_USD = 'usd';
export const ORDER_MIN = 'OrderMin';

export const CUSTOM_SIGNS_ATTRIBUTES = {
	MOUNTOPTION: 'Mounting Holes',
	size: 'Size (H x W)',
	reorderCode: 'Reorder Number',
	substrate: 'Material',
};
export const CONFIGURED_PRODUCTS_ATTRIBUTES = {
	size: 'Size (W x H)',
	substrate: 'Material',
};

export const IMPRINT_SEAL_PRODUCTS_ATTRIBUTES = {
	sealcolor: 'Color',
	barcode: 'Numbering Type',
	cable_length: 'Length',
	imprintCartAttr: 'Imprint Line 1',
	startingNoCartAttr: 'Starting Number',
};

export const IMPRINT_SEAL_PRODUCTS_REORDER_ATTRIBUTES = {
	imprintAttr: 'Imprint',
	barcode: 'Barcode Type',
	startingNoAttr: 'Starting Number',
	cable_length: 'Cable Length',
	sealcolor: 'Seal Color',
};

export const ORDER_TYPE = {
	RECURRING: 'REC',
};

export const QUANTITY_FIELD_MAX_LENGTH = 12;

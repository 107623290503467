/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const previewWidgetFrameInfoIconSX: SxProps = {
	color: 'info.dark',
};

import { ContentType, HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class SearchDocumentResource<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags Search-Document-Resource
	 * @name GetMarketingESpotData
	 * @summary Gets BOD data.
	 * @request POST:/api/v2/documents/bod
	 * @response `200` `string` The request is completed successfully.
	 * @response `400` `string` Bad request. Some of the inputs provided to the request are not valid.
	 * @response `404` `string` The specified resource could not be found.
	 * @response `500` `string` Internal server error. Additional details will be contained on the server logs.
	 */
	getMarketingESpotData = (data: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getMarketingESpotData'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/api/v2/documents/bod`,
				params: _params,
				body: data,
				methodName: 'getMarketingESpotData',
			};
			logger.trace(traceData);
		}
		return this.http.request<string, string>({
			path: `/api/v2/documents/bod`,
			method: 'POST',
			body: data,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}

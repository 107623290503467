/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const TextField: ThemeOptions = {
	components: {
		MuiInputLabel: {
			styleOverrides: {
				outlined: getStyleOverrides({
					styles: {
						fontSize: '12pt',
						backgroundColor: 'white',
						color: 'secondary.lightgrey',
						'&.Mui-focused': {
							color: 'secondary.lightgrey',
						},
						'&.Mui-error': {
							borderColor: 'text.error',
							color: 'text.error',
						},
						/* '&.MuiOutlinedInput-notchedOutline':{
							fontSize: '12pt',
						  }, */
					},
				}),
				required: getStyleOverrides({
					styles: {
						'.MuiInputLabel-asterisk': {
							display: 'none',
						},
					},
				}),
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						'&.MuiInputBase-root.Mui-focused': {
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: 'button.focusBorder',
								borderWidth: '2px',
							},
						},
						'&.MuiInputBase-root.Mui-error': {
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: 'text.error',
							},
						},
						'&.MuiInputBase-root:hover': {
							'& fieldset.MuiOutlinedInput-notchedOutline': {
								borderColor: 'button.focusBorder',
							},
						},
					},
				}),
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						backgroundColor: 'textField.background',
						'&:hover,&:focus': {
							borderColor: 'textField.borderHovered2',
						},
					},
				}),
			},
		},
	},
};

import { ThemeOptions } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { Box } from '@mui/material';

const iconStyle = {
    '& svg': {
        color: 'secondary.lightgrey',
        marginRight: '6px',
        width: '14px',
    },
};

export const Select: ThemeOptions = {
    components: {
        MuiSelect: {
            defaultProps: {
                IconComponent: (props) => <Box sx={iconStyle}><FontAwesomeIcon icon={faAngleDown} {...props} /></Box>,
            },
        },
    },
};

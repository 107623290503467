/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Theme, LinkProps, Interpolation } from '@mui/material';
import { palette } from '@/styles/JJKeller/palette';

export const LinkVariants: {
	props: Partial<LinkProps>;
	style: Interpolation<{ theme: Theme }>;
}[] = [
	{
		props: { variant: 'primary' },
		style: {
			color: palette.palette?.links?.primary,
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
	{
		props: { variant: 'secondary' },
		style: {
			color: palette.palette?.links?.secondary,
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
	{
		props: { variant: 'contrastText' },
		style: {
			color: palette.palette?.links.contrastText,
			'&:hover': {
				color: palette.palette?.links.contrastText,
				textDecoration: 'underline',
			},
		},
	},
	{
		props: { variant: 'blue-link' },
		style: {
			color: palette.palette?.button?.blue,
		},
	},
	{
		props: { variant: 'white-link' },
		style: {
			color: `${palette.palette?.links.white} !important`,
			'&:hover': {
				color: `${palette.palette?.links.white} !important`,
				textDecoration: 'underline',
			},
		},
	},
	{
		props: { variant: 'blue-link-dropdown' },
		style: {
			color: palette.palette?.button?.blue,
			fontWeight: 600,
			/* textDecoration: 'underline', */
			'&:hover': {
				color: palette.palette?.button?.blue,
				textDecoration: 'underline',
				'& .fa-chevron-right': {
					color: palette.palette?.button?.disabled, // chevron styling for cmc dropdown
				},
			},
		},
	},
	{
		props: { variant: 'blue-link-chevron' },
		style: {
			color: palette.palette?.button?.blue,
			fontWeight: 600,
			/* textDecoration: 'underline', */
			'&:hover': {
				color: palette.palette?.button?.disabled,
			},
		},
	},
	{
		props: { variant: 'blue-link-espot' },
		style: {
			color: palette.palette?.button?.blue,
			fontWeight: 600,
			'&:hover': {
				color: palette.palette?.button?.blue,
				'text-underline-offset': '3px',
			},
		},
	},
	{
		props: { variant: 'blue-link-article' },
		style: {
			color: palette.palette?.button?.blue,
			'&:hover': {
				color: `${palette.palette?.button.blue} !important`,
				textDecoration: 'underline',
			},
		},
	},
	{
		props: { variant: 'link-underline' },
		style: {
			textDecoration: 'underline',
			fontWeight: 600,
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
];

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		primary: true;
		secondary: true;
		contrastText: true;
		'blue-link': true;
		'white-link': true;
		'blue-link-dropdown': true;
		'blue-link-chevron': true;
		'blue-link-article': true;
		'blue-link-espot': true;
		'link-underline': true;
	}
}

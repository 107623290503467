import { generateSignOutUri, generateRedirectUri } from '@/data/utils/OktaConfigs';
import { OktaAuth } from '@okta/okta-auth-js';
import {
	OKTALOGINFORM,
	OKTACOOKIESNAME,
	OKTATOKENUSER,
	OKTA_CONFIG,
	FORMDATAHEADER,
} from '@/data/constants/signIn';
import useSWR from 'swr';
import { Cache } from '@/data/types/Cache';
import { DATA_KEY_OKTA_CLIENT_ID, DATA_KEY_OKTA_ISSUER_ID } from '../constants/dataKey';

export const getOktaInitConfig = async (cache: Cache) => {
	cache.set(DATA_KEY_OKTA_ISSUER_ID, process.env.OKTA_ISSUER_ID);
	cache.set(DATA_KEY_OKTA_CLIENT_ID, process.env.OKTA_CLIENT_ID);
};
export const useOktaInitConfigValues = () => {
	const { data: issuer } = useSWR(DATA_KEY_OKTA_ISSUER_ID);
	const { data: clientId } = useSWR(DATA_KEY_OKTA_CLIENT_ID);

	return {
		issuer,
		clientId,
	};
};

export const useOkta = () => {
	const { issuer, clientId } = useOktaInitConfigValues();

	const hostName =
		process.env.NODE_ENV === 'development'
			? 'localhost'
			: typeof window !== 'undefined'
			? location?.hostname
			: 'undefined';

	const oktaConfig = {
		...OKTA_CONFIG,
		issuer,
		clientId,
		redirectUri: generateRedirectUri(hostName),
	};
	const handleOktaAuthentication = async (formJson: any) => {
		const authClient = new OktaAuth(oktaConfig);
		localStorage.setItem(OKTALOGINFORM, JSON.stringify(formJson));
		authClient.start();
		if (authClient.isLoginRedirect()) {
			try {
				await authClient.handleLoginRedirect();
			} catch (e) {
				// log or display error details
			}
		} else if (!(await authClient.isAuthenticated())) {
			// Start the browser based oidc flow, then parse tokens from the redirect callback url
			authClient.signInWithRedirect();
		} else {
			// User is authenticated
		}
	};

	// Okta SignOut
	const handleOktaSignOut = async () => {
		const authClient = new OktaAuth(oktaConfig);
		authClient.start();
		localStorage.removeItem(OKTALOGINFORM);
		localStorage.removeItem(OKTATOKENUSER);
		OKTACOOKIESNAME?.map(
			(cookie) => (document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
		);
		await authClient.signOut({
			postLogoutRedirectUri: generateSignOutUri(hostName),
		});
	};

	return {
		handleOktaAuthentication,
		handleOktaSignOut,
	};
};

export const getOktaSignInOrderFormData = (count: number) => {
	// add URL obj based on cart value
	const newOrderFormData = FORMDATAHEADER;
	let orderUri = null;
	if (count > 0) {
		orderUri = {
			type: 'hidden',
			name: 'URL',
			value:
				'OrderItemMove?URL=OrderCalculate?URL=LogonForm?langId=-1&storeId=10151&catalogId=10551&page=account&fromOrderId=*&toOrderId=.&deleteIfEmpty=*&continue=1&createIfEmpty=1&calculationUsageId=-1&updatePrices=1&doInventory=N&storeId=10151&catalogId=10551&globalLogIn=true&reactURL=register',
		};
	} else {
		orderUri = {
			type: 'hidden',
			name: 'URL',
			value:
				'RESTOrderCalculate?URL=LogonForm?langId=-1&storeId=10151&catalogId=10551&calculationUsageId=-1&deleteCartCookie=true&page=&catalogId=10551&storeId=10151&globalLogIn=true&reactURL=register',
		};
	}
	newOrderFormData.push(orderUri);
	return newOrderFormData;
};

import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Alert: ThemeOptions = {
	components: {
		MuiAlert: {
			styleOverrides: {
				standardError: {
					color: '#000',
					border: '#CA251F solid 1px',
					' .MuiAlert-icon': { display: 'flex', alignItems: 'center' },
				},
			},
		},
	},
};

import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class EditorialTopicsSearch<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Search of editorail Topics
	 *
	 * @tags editorialTopicsSearch
	 * @name EditorialTopicsSearch
	 * @summary Search editorail Topics
	 * @request GET:/store/{storeId}/editorial/topics/search/{keyword}
	 * @secure
	 * @response `200` `any` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	editorialTopicsSearch = (storeId: string, keyword: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['editorialTopicsSearch'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/editorial/topics/search/${keyword}`,
				params: _params,
				methodName: 'editorialTopicsSearch',
			};
			logger.trace(traceData);
		}
		return this.http.request<any, void>({
			path: `/store/${storeId}/editorial/topics/search/${keyword}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}

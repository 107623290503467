import { Layout } from '@/data/types/Layout';

export const getAccountSettingsPage = (): Layout => ({
	name: 'AsideMobileColumnReverse',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		aside: [{ name: 'AccountSidebar', id: 'side-bar' }],
		first: [{ name: 'StaticBreadcrumbTrail', id: 'account-settings' }],
		second: [{ name: 'AccountSettings', id: 'account-settings' }],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});

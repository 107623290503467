/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';
import { LinkVariants } from '@/styles/JJKeller/Link/variants';

export const Link: ThemeOptions = {
	components: {
		MuiLink: {
			variants: LinkVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						color: 'links.primary',
						textDecoration: 'none',
						fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
						'&:hover': {
							color: 'links.hover',
							textDecoration: 'underline',
						},
					},
				}),
			},
		},
	},
};

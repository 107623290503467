/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const PERSISTENT = 'WC_PERSISTENT';
export const WC_PREFIX = 'WC_';
export const WCP_PREFIX = 'WCP_';
export const PREVIEW_TOKEN_PREFIX = 'WCPreviewToken_';
/**
 * Max age of cookie if it is not session. 30 days.
 */
export const MAX_AGE = 30 * 24 * 60 * 60;

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeOptions } from '@mui/material/styles';

export type customPalette = ThemeOptions & {
	palette: {
		button: {
			primaryActive: string;
			blue: string;
            blueHover: string;
			blueActive: string;
			focusBorder: string;
		};
		textField: {
			borderHovered2: string;
		};
		links: {
			blue: string;
			white: string;
			primary: string;
			secondary: string;
			contrastText: string;
			hover: string;
		};
		border: {
			dark: string;
			alert: string;
			footer: string;
			gray: string;
			lighterGray: string;
			lightGray: string;
			darkerGray: string;
			yellow: string;
		};
		icons: {
			fillcolor: string;
			blue: string;
			ratingColor: string;
		};
		background: {
			paper: string;
			dark: string;
		};
		text: {
			success: string;
			error: string;
		};
	};
};

export const palette: customPalette = {
	palette: {
		primary: {
			main: '#58132a',
			light: '#fbdde0',
			dark: '#660b13',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#926b6f',
			light: '#1ec79f',
			dark: '#007055',
			contrastText: '#ffffff',
			lightgrey: '#656D73',
		},
		text: {
			main: '#4A5055',
			primary: '#43484d',
			secondary: '#5e6977',
			disabled: '#b5beca',
			highlight: '#93101c',
			alert: '#c10c0d',
			expandedMenu: '#ffffff',
			darkGray: '#43484d',
			success: '#008060',
			error: '#CA251F',
			disabledText: '#899298',
			textUnderline: '#757a7e',
		},
		base: {
			main: '#43484d',
			contrastText: '#ffffff',
		},
		action: {
			active: '#cccccc',
			hover: '#f0f4f7',
			disabled: '#75767a',
			disabledBackground: '#ddcfcf',
		},
		background: {
			main: '#fdfdfc',
			default: '#fafafb',
			paper: '#ffffff',
			transparency: 'cc', // used to combine hex colors with transparency (00-ff), e.g., #ffffffcc
			contrastText: '#43484d',
			secondaryLight: '#752A43',
			secondaryLightHover: '#ffffff80',
			lightgray: '#F7F7F7',
			modal: '#F7F7F7',
			tabelHeadingBg: '#EBEEEF',
			lightGray: '#d9d9d9',
			darkGray: '#c7ced2',
			navy: '#EBF1F5',
			dark: '#323638',
			defaultAlert: '#FEECEC',
		},
		divider: '#ebebea',
		border: {
			dark: '#4c5256',
			alert: '#c10c0d',
			footer: '#888',
			gray: '#BDC5CA',
			grayDark: '#D8DBDD',
			grayHover: '#d8dbdd',
			orange: '#DC8538',
			lighterGray: '#e2e4e5',
			lightGray: '#e5e7e9',
			darkerGray: '#c7ced2',
			yellow: '#fadaac',
		},
		textField: {
			border: '#660b13',
			borderHovered: '#3a060b',
			borderHovered2: '#326E99',
			background: '#fdfdfd',
			disabledBackground: '#ddcfcf',
		},
		button: {
			primary: '#752A43',
			primaryHover: '#8D4960',
			primaryActive: '#58132A',
			secondary: '#ffffff',
			secondaryHover: '#ffffff',
			contrastText: '#ffffff',
			disabled: '#D8DBDD',
			disabledText: '#899298',
			gray: '#BDC5CA',
			grayHover: '#d8dbdd',
			blue: '#326E99',
            blueHover: '#5C8CAE',
			blueActive: '#004677',
			blueFilledHover: '#5c8cae',
			blueOutlineHoverText: '#709ab8',
			focusBorder: '#191B1C',
		},
		icons: {
			fillcolor: '#656D73',
			fillcolorHover: '#a3aaaf',
			gray: '#191B1C',
			blue: '#006a87',
			ratingColor: '#EFAE50',
		},
		links: {
			blue: '#326E99',
			white: '#ffffff',
			primary: '#326E99',
			secondary: '#4A5055',
			contrastText: '#ffffff',
			hover: '#004677',
		},
		heading: {
			dark: '#323638',
		},
		radio: {
			burgundy: '#8D4960',
		},
	},
} as customPalette;

import { Layout } from '@/data/types/Layout';

export const getOrderStatusPage = (): Layout => ({
	name: 'AsideMobileColumnReverse',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		aside: [{ name: 'NewCustomerServiceSidebar', id: 'side-bar' }],
		second: [{ name: 'OrderStatus', id: 'order-status' }],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Stack, Drawer, Box, Typography, Button } from '@mui/material';
import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useCart } from '@/data/Content/Cart';
import { useLocalization } from '@/data/Localization';
import { cartContainerSX } from '@/components/blocks/Cart/styles/cartContainer';
import { dFix } from '@/utils/floatingPoint';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { Img } from '@/components/blocks/MaterialImage';
import { ProductAttributeValue, ProductDisplayPrice } from '@/data/types/Product';
import { Linkable } from '@/components/blocks/Linkable';
import { OrderItem } from '@/data/types/Order';
import { useProductDetails } from '@/data/Content/ProductDetails';
import { S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP } from '@/data/constants/product';
import { ShoppingCart } from '@mui/icons-material';
import { OrderItemReduced } from '@/data/types/Order';
import { useProduct } from '@/data/Content/Product';

type Props = {
	drawerOpen: boolean;
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	name?: string | undefined;
	quantity?: number;
	colorSwatches?: ProductAttributeValue[] | undefined;
	productPrice?: ProductDisplayPrice | undefined;
	thumbnail?: string | undefined;
	orderList?: OrderItemReduced[] | undefined;
	cartOrderItemsId?: string[] | undefined;
};
export const Cart: FC<Props> = ({
	drawerOpen,
	setDrawerOpen,
	name,
	productPrice,
	thumbnail,
	quantity,
	orderList,
	cartOrderItemsId,
}) => {
	const router = useNextRouter();
	const CartLabels = useLocalization('Cart');
	const RouteLocal = useLocalization('Routes');
	const { data: order, canContinue, count, orderItems: cartOrderItems } = useCart();
	const totalPrice = order?.totalProductPrice ?? '0.00';
	const currency = order?.totalProductPriceCurrency;
	const locale = React.useMemo(() => router.locale ?? router.defaultLocale, [router]);
	// Here in line 51 we are filtering items that have added to cart based on the cartOrderItemsId
	const addedItemsInCart = cartOrderItems?.filter((item) => cartOrderItemsId?.includes(item?.orderItemId));
	const selectedItems = addedItemsInCart?.map(({ partNumber, quantity }) => ({ partNumber, quantity })) ?? ([] as OrderItem[]);

	const orderItems = orderList === undefined ? selectedItems : orderList || [];
	const filterItems =
		orderItems?.length > 3 ? orderItems?.slice(0, 3) : orderItems;
	const itemsCount = count - filterItems?.length;
	const orderItemPrice = Array.isArray(cartOrderItems) ? cartOrderItems[0]?.orderItemPrice : null;
	const prodThumbnail = S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP + thumbnail;
	const handleCartRedirect = () => {
		router.push({ pathname: RouteLocal.Cart.route.t() });
		setDrawerOpen(false);
	};
	return (
		<Drawer anchor={'right'} open={drawerOpen}>
			<Box sx={cartContainerSX}>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Typography variant={'h3'} component={'h3'} sx={{ marginBottom: 1 }}>
						{CartLabels.Msgs.AddedToCart.t()}
					</Typography>
					<CloseIcon onClick={() => setDrawerOpen(false)} sx={{ cursor: 'pointer' }} />
				</Stack>
				<Stack
					sx={{
						borderBottom: '1px solid',
						borderColor: 'border.grayDark',
						paddingBottom: 2,
						marginBottom: 2,
					}}
				>
					<Typography variant="body2">
						{CartLabels.CountTotal.t({
							count,
						})}
					</Typography>
				</Stack>

				{filterItems && filterItems?.length ? (
					filterItems?.map((orderItem, index) => (
						<CartContent key={index} orderItem={orderItem} locale={locale} cartOrderItems={cartOrderItems} />
					))
				) : (
					<Stack
						direction={'row'}
						sx={{ backgroundColor: 'background.paper', padding: 2, marginBottom: 3 }}
					>
						<Stack sx={{ marginRight: 2, flex: 'none' }}>
							<Img src={prodThumbnail} alt="Product Image" sx={{ width: '94px', height: 'auto' }} />
						</Stack>
						<Stack direction={'column'} spacing={0.7}>
							{name ? <Typography variant="h7">{name}</Typography> : null}
							{quantity ? (
								<Typography variant="body2">
									{CartLabels.Attributes.Quantity.t({ quantity })}
								</Typography>
							) : null}
							{orderItemPrice && productPrice ? (
								<Typography variant="body2Bold">
									{CartLabels.Attributes.ListPrice.t({
										price: Intl.NumberFormat(locale, {
											style: 'currency',
											currency: productPrice?.currency,
										}).format(dFix(orderItemPrice as string | number)),
									})}
								</Typography>
							) : null}
						</Stack>
					</Stack>
				)}

				{filterItems && cartOrderItems?.length > 3 ? (
					<Typography sx={{ marginBottom: '16px', lineHeight: '18px' }}>
						{CartLabels.ItemsLeftInCart.t({
							itemsCount,
						})}
					</Typography>
				) : null}

				<Stack
					spacing={2}
					sx={{ backgroundColor: 'background.paper', padding: 2, marginBottom: 3 }}
				>
					{currency ? (
						<Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
							<Typography variant="subtitle1" align="center">
								{CartLabels.Subtotal.t()}
							</Typography>

							<Typography variant="subtitle2" align="center">
								{Intl.NumberFormat(locale, { style: 'currency', currency }).format(
									dFix(totalPrice)
								)}
							</Typography>
						</Stack>
					) : null}

					<Button
                        onClick={() => setDrawerOpen(false)}
                        fullWidth
                        size="large"
                        variant="blue-outlined"
                    >
						{CartLabels.Actions.ContinueShopping.t()}
					</Button>

					<Button
                        fullWidth
                        size="large"
						variant="burgundy-contained"
						// href="/cart"
						disabled={!canContinue()}
						onClick={handleCartRedirect}
					>
						<ShoppingCart fontSize="small" sx={{ marginRight: '8px', fontSize: '16px' }} />
						{CartLabels.Actions.ViewCart.t()}
					</Button>
				</Stack>
			</Box>
		</Drawer>
	);
};

export const CartContent: FC<{ orderItem: OrderItemReduced; locale: string | undefined, cartOrderItems: OrderItem[] }> = ({
	orderItem,
	locale,
	cartOrderItems,
}) => {
	const CartLabels = useLocalization('Cart');
	const { product } = useProduct({ id: orderItem?.partNumber });
	const thumbnail = S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP + product?.thumbnail;
	const name = product?.name;
	const productPrice = product?.productPrice;
	const quantity = parseInt(orderItem?.quantity);
	const orderItemWithPrice = cartOrderItems?.find((item) => item.partNumber === orderItem?.partNumber);
	return (
		<Stack
			direction={'row'}
			sx={{ backgroundColor: 'background.paper', padding: 2, marginBottom: 3 }}
		>
			<Stack sx={{ marginRight: 2, flex: 'none' }}>
				<Img src={thumbnail} alt="Product Image" sx={{ width: '94px', height: 'auto' }} />
			</Stack>
			<Stack direction={'column'} spacing={0.7} >
				{name ? <Typography variant="h7">{name}</Typography> : null}
				{quantity ? (
					<Typography variant="body2">{CartLabels.Attributes.Quantity.t({ quantity })}</Typography>
				) : null}
				{orderItemWithPrice && productPrice ? (
					<Typography variant="body2" sx={{ fontWeight: '600' }}>
						{CartLabels.Attributes.ListPrice.t({
							price: Intl.NumberFormat(locale, {
								style: 'currency',
								currency: productPrice.currency,
							}).format(dFix(orderItemWithPrice?.orderItemPrice as string | number)),
						})}
					</Typography>
				) : null}
			</Stack>
		</Stack>
	);
};

import { ComIbmCommerceRestB2BAddressesHandler } from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class B2BAddresses<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Get shipping addresses for b2b user
	 *
	 * @tags B2bAddresses
	 * @name B2BShippingAddress
	 * @summary Get shipping addresses for b2b user
	 * @request GET:/store/{storeId}/jjkperson/shipping/{userId}
	 * @secure
	 * @response `200` `ComIbmCommerceRestB2BAddressesHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	b2BShippingAddress = (userId: string, storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['b2BShippingAddress'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkperson/shipping/${userId}`,
				params: _params,
				methodName: 'b2BShippingAddress',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestB2BAddressesHandler, void>({
			path: `/store/${storeId}/jjkperson/shipping/${userId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Get billing addresses for b2b user
	 *
	 * @tags B2bAddresses
	 * @name B2BBillingAddress
	 * @summary Get billing addresses for b2b user
	 * @request GET:/store/{storeId}/jjkperson/billing/{userId}
	 * @secure
	 * @response `200` `ComIbmCommerceRestB2BAddressesHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	b2BBillingAddress = (userId: string, storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['b2BBillingAddress'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkperson/billing/${userId}`,
				params: _params,
				methodName: 'b2BBillingAddress',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestB2BAddressesHandler, void>({
			path: `/store/${storeId}/jjkperson/billing/${userId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}

import { Layout } from '@/data/types/Layout';

export const getCoBrandCategoryDetailPage = (): Layout => ({
	name: 'DoubleStack',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [{ name: 'CoBrandCategoryDetail', id: 'cobrand-category-detail' }],
		second: [],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material';
import { AccordionVariants } from '@/styles/JJKeller/Accordion/variants';
import { palette } from '@/styles/JJKeller/palette';
import { typography } from '@/styles/JJKeller/type';

export const Accordion: ThemeOptions = {
	components: {
		MuiAccordion: {
			variants: AccordionVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						':first-of-type': {
							'.MuiAccordionSummary-root': {
								borderTopLeftRadius: '5px',
								borderTopRightRadius: '5px',
							},
						},
						':last-of-type': {
							borderBottom: '1px solid #BDC5CA',
							'.MuiAccordionSummary-root': {
								borderBottomLeftRadius: '0',
								borderBottomRightRadius: '0',
							},
						},

						boxShadow: 'none',
						'&.MuiAccordion-root.Mui-expanded': {
							margin: 0,
							'&:before': {
								opacity: 1,
							},
						},
					},
				}),
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						borderTop: '1px solid #BDC5CA',
						borderLeft: '1px solid #BDC5CA',
						borderRight: '1px solid #BDC5CA',

						minHeight: 'initial',
						padding: '.7rem',

						'.expand-less': {
							display: 'none',
							color: palette.palette?.icons.fillcolor,
							fontSize: '40px',
						},
						'.expand-more': {
							display: 'block',
							color: palette.palette?.icons.fillcolor,
							fontSize: '40px',
						},
						'&.Mui-expanded': {
							minHeight: 'initial',
							'.expand-less': {
								display: 'block',
							},
							'.expand-more': {
								display: 'none',
							},
						},
						'.MuiAccordionSummary-expandIconWrapper': {
							py: 1.5,
							px: 0,
						},

						'.MuiAccordionSummary-content': {
							margin: 0,
							justifyContent: 'space-between',
							display: 'flex',
							alignItems: 'center',
							'&.Mui-expanded': {
								minHeight: 'initial',
								margin: 0,
							},
						},
					},
				}),
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						borderLeft: '1px solid #BDC5CA',
						borderRight: '1px solid #BDC5CA',
						padding: '0 1rem 1rem 1rem',
						fontSize: '14px',
					},
				}),
			},
		},
	},
};

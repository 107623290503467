export const getAddToCartParams = (href: string) => {
	const queryIndex = href?.indexOf('?');
	let partNumber: string | undefined | null = undefined;
	let quantity: string | undefined | null = undefined;
	if (queryIndex !== -1) {
		const substr = href?.slice(queryIndex);
		const searchParams = new URLSearchParams(substr);
		partNumber = searchParams.get('partnumber');
		quantity = searchParams.get('quantity');
	}
	return {
		partNumber,
		quantity,
	};
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export interface Breakpoints {
	breakpoints?: {
		values: { [key in Breakpoint]: number };
	};
}
export const breakpoints: Breakpoints = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1440,
			xxl: 1920,
		},
	},
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme, ButtonProps, Interpolation } from '@mui/material';
import { palette } from '@/styles/JJKeller/palette';

export const buttonVariants: {
	props: Partial<ButtonProps<'button', SxProps>>;
	style: Interpolation<{ theme: Theme }>;
}[] = [
    {
		props: { width: 'wide' },
		style: {
            width: '100%',
			maxWidth: '220px',
		},
	},
	{
		props: { variant: 'blue-link' },
		style: {
			backgroundColor: 'transparent',
			color: palette.palette?.button?.blue,
			padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
            '&:focus-visible': {
                border: `1px solid transparent`,
                outline: `2px solid ${palette.palette.button.focusBorder}`,
                outlineOffset: '0',
            },
		},
	},
	{
		props: { variant: 'blue-underline' },
		style: {
			backgroundColor: 'transparent',
			color: palette.palette?.button?.blue,
			padding: 0,
			'&:hover': {
				textDecoration: 'underline',
				textDecorationColor: palette.palette?.button?.blue,
				'text-underline-offset': '3px',
			},
            '&:focus-visible': {
                border: `1px solid transparent`,
                outline: `2px solid ${palette.palette.button.focusBorder}`,
                outlineOffset: '0',
            },
		},
	},
	{
		props: { variant: 'blue-contained' },
		style: {
			backgroundColor: palette.palette.button.blue,
			color: palette.palette.button.contrastText,
			borderColor: palette.palette.button.blue,
			'&:hover': {
                backgroundColor: palette.palette.button.blueHover,
                borderColor: palette.palette.button.blueHover,
			},
			'&:active': {
				backgroundColor: palette.palette?.button.blueActive,
                borderColor: palette.palette.button.blueActive,
			},
			'&:focus-visible': {
				border: `1px solid ${palette.palette.button.secondary}`,
				outline: `2px solid ${palette.palette?.button.focusBorder}`,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'blue-outlined' },
		style: {
			backgroundColor: 'transparent',
			color: palette.palette.button.blue,
			borderColor: palette.palette.button.blue,
			'&:active': {
				borderColor: palette.palette.button.blueActive,
				color: palette.palette.button.blueActive,
			},
			'&:hover': {
				backgroundColor: palette.palette.button.secondary,
			},
			'&:focus-visible': {
				border: `1px solid ${palette.palette.button.blue}`,
				outline: `2px solid ${palette.palette.button.focusBorder}`,
                outlineOffset: '0',
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'blue-no-outline' },
		style: {
			backgroundColor: palette.palette.button.secondary,
			color: palette.palette.button.blue,
			'&:active': {
				color: palette.palette.button.blueActive,
				backgroundColor: palette.palette.button.secondary,
			},
			'&:hover': {
				opacity: '0.8',
				color: palette.palette.button.blue,
				backgroundColor: palette.palette.button.secondary,
				boxShadow: 'none',
			},
			'&:focus-visible': {
				// color: palette.palette.button.secondary,
				border: `1px solid transparent`,
                outline: `2px solid ${palette.palette.button.focusBorder}`,
				backgroundColor: palette.palette.button.secondary,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'checkout-contained' },
		style: {
			backgroundColor: palette.palette.button.primary,
			color: palette.palette.button.secondary,
			borderColor: palette.palette.button.primary,
			border: '1px solid',
			'&:active': {
				backgroundColor: palette.palette.button.primaryActive,
			},
			'&:hover': {
				opacity: '0.8',
				color: palette.palette.button.secondary,
				backgroundColor: palette.palette.button.primary,
				boxShadow: 'none',
			},
            '&:focus-visible': {
				backgroundColor: palette.palette.button.primary,
				color: palette.palette.button.secondary,
				border: `1px solid ${palette.palette.button.secondary}`,
				outline: `2px solid ${palette.palette.button.focusBorder}`,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'burgundy-contained' },
		style: {
			backgroundColor: palette.palette.button.primary,
			color: palette.palette.button.secondary,
			borderColor: palette.palette.button.primary,
			border: '1px solid',
			'&:active': {
				backgroundColor: palette.palette.button.primaryActive,
			},
			'&:hover': {
				opacity: '0.8',
				color: palette.palette.button.secondary,
				backgroundColor: palette.palette.button.primary,
				boxShadow: 'none',
			},
			'&:focus-visible': {
				backgroundColor: palette.palette.button.primary,
				color: palette.palette.button.secondary,
				border: `1px solid ${palette.palette.button.secondary}`,
				outline: `2px solid ${palette.palette.button.focusBorder}`,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'burgundy-outlined' },
		style: {
			backgroundColor: palette.palette.button.secondary,
			color: palette.palette.button.primary,
			borderColor: palette.palette?.button.primary,
			border: '1px solid',
			padding: '8px 24px',
			'&:active': {
				borderColor: palette.palette.button.primaryActive,
				color: palette.palette.button.primaryActive,
				backgroundColor: palette.palette.button.secondary,
			},
			'&:hover': {
				opacity: '0.8',
				backgroundColor: palette.palette.button.secondary,
				boxShadow: 'none',
				color: palette.palette.button.primary,
			},
			'&:focus-visible': {
				color: palette.palette?.button.primary,
				border: `1px solid ${palette.palette.button.primary}`,
				outline: `2px solid ${palette.palette.button.focusBorder}`,
				backgroundColor: palette.palette.button.secondary,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'burgundy-no-outline' },
		style: {
			backgroundColor: palette.palette.button.secondary,
			color: palette.palette.button.primary,
			padding: '8px 24px',
			'&:active': {
				color: palette.palette.button.primaryActive,
				backgroundColor: palette.palette.button.secondary,
			},
			'&:hover': {
				opacity: '0.8',
				backgroundColor: palette.palette.button.secondary,
				boxShadow: 'none',
				color: palette.palette.button.primary,
			},
			'&:focus-visible': {
				color: palette.palette.button.primary,
				backgroundColor: palette.palette.button.secondary,
				border: `1px solid ${palette.palette.button.focusBorder}`,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
	{
		props: { variant: 'blue-search' },
		style: {
			backgroundColor: palette.palette.button.blue,
			color: palette.palette.button.secondary,
			borderColor: palette.palette.button.blue,
			borderTopLeftRadius: '0',
			borderBottomLeftRadius: '0',
			'&:hover': {
				backgroundColor: palette.palette.button.blueHover,
                borderColor: palette.palette.button.blueHover,
				color: palette.palette.button.secondary,
			},
			'&:active': {
				backgroundColor: palette.palette?.button.blueActive,
                borderColor: palette.palette.button.blueActive,
			},
            '&:focus-visible': {
				border: `1px solid ${palette.palette.button.secondary}`,
				outline: `2px solid ${palette.palette?.button.focusBorder}`,
			},
			'&.Mui-disabled': {
				backgroundColor: palette.palette.button.disabled,
			},
		},
	},
];

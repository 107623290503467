/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const GENERIC_USER_ERROR = 'USR.CWXFR0130E';
export const EXPIRED_ACTIVITY_TOKEN_ERROR = 'CWXBB1011E';
export const INVALID_COOKIE_ERROR_KEY = '_ERR_INVALID_COOKIE';
export const INVALID_COOKIE_ERROR_CODE = 'CMN1039E';
export const ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_CODE = 'CWXFR0221E';
export const ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_KEY = 'ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS';
export const SESSION_TIMEOUT_ERROR = 'sessionTimeout';
export const SESSION_INVALID_ERROR = 'sessionInvalid';
export const ACCOUNT_LOCKOUT_ERROR = '2490';
export const CART_LOCKED = '_ERR_ORDER_IS_LOCKED';
export const PASSWORD_EXPIRED = '_ERR_PASSWORD_EXPIRED';
export const PASSWORD_EXPIRED_ERR_CODE = '2170';
export const ACCOUNT_NOT_ACTIVATED = '2450';
export const PARTIAL_AUTHENTICATION_ERROR_CODE = 'CWXFR0222E';
export const PARTIAL_AUTHENTICATION_ERROR_KEY = 'ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWE';
export const CMC_SESSION_ERROR_KEY = 'CMC_RELOGON';
export const ACTIVITY_TOKEN_ERROR_KEY = '_ERR_ACTIVITY_TOKEN';
export const ACTIVITY_TOKEN_ERROR_CODE = 'CWXBB1103E';
export const COOKIE_EXPIRED_ERROR_CODE = 'CWXFR0223E';
export const COOKIE_EXPIRED_ERROR_KEY = 'ERR_COOKIE_EXPIRED';
export const BAD_INV_ERROR = '_API_BAD_INV';
export const BAD_INV_DELIVERY_ERROR_KEY = '_API_BAD_INV_DELIVERY';
export const BAD_INV_PICKUP_ERROR_KEY = '_API_BAD_INV_PICKUP';
export const ERR_CMD_INVALID_PARAM = '_ERR_CMD_INVALID_PARAM';
export const ELASTIC_SEARCH_INVALID_COOKIE_KEY = 'CWXFR0231E';

export const TIMEOUT_ERRORS: Record<string, string> = {
	[ACTIVITY_TOKEN_ERROR_CODE]: ACTIVITY_TOKEN_ERROR_CODE,
	[ACTIVITY_TOKEN_ERROR_KEY]: ACTIVITY_TOKEN_ERROR_KEY,
	[COOKIE_EXPIRED_ERROR_CODE]: COOKIE_EXPIRED_ERROR_CODE,
	[COOKIE_EXPIRED_ERROR_KEY]: COOKIE_EXPIRED_ERROR_KEY,
	[EXPIRED_ACTIVITY_TOKEN_ERROR]: EXPIRED_ACTIVITY_TOKEN_ERROR,
};

export const COOKIE_ERRORS: Record<string, string> = {
	[INVALID_COOKIE_ERROR_KEY]: INVALID_COOKIE_ERROR_KEY,
	[INVALID_COOKIE_ERROR_CODE]: INVALID_COOKIE_ERROR_CODE,
	[ELASTIC_SEARCH_INVALID_COOKIE_KEY]: ELASTIC_SEARCH_INVALID_COOKIE_KEY,
	[ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_CODE]: ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_CODE,
	[ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_KEY]: ERR_AUTHENTICATION_COOKIE_NOT_IN_HTTPS_KEY,
};

export const ERROR_TYPE = {
	session: 'session-error',
	common: 'common-error',
	shippingInfo: 'shipping-info-error',
};

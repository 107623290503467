export const scrollToTarget = (id: string, isMobile: boolean) => {
    const element = document.getElementById(id);
    const headerOffset = isMobile ? 234 : 0;
    const elementPosition = element?.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
         top: offsetPosition,
         behavior: 'smooth',
    });
};

// remaning change hardcoded storeId and CatlogID

export const OKTA_CONFIG = {
	responseType: 'code',
	scopes: ['openid', 'email', 'offline_access'],
	pkce: false,
	tokenManager: {
		storage: 'localStorage',
	},
};

export const OKTALOGINFORM = 'loginForm';
export const OKTATOKENUSER = 'tokenUser';

export const OKTACOOKIESNAME = [
	'WC_DeleteCartCookie_10151',
	'okta-oauth-redirect-params',
	'okta-oauth-state',
	'okta-oauth-nonce',
];

export const FORMDATAHEADER = [
	{ type: 'name', elementType: 'formAttribute', value: 'Header_GlobalLogin_GlobalLogon' },
	{ type: 'data-baseform', elementType: 'formAttribute', value: 'globallogonform' },
	{ type: 'id', elementType: 'formAttribute', value: 'okta_Header_GlobalLogin_GlobalLogon' },
	{ type: 'class', elementType: 'formAttribute', value: 'hidden' },
	{
		href: '#',
		elementType: 'a',
		type: 'onclick',
		id: 'submitForm',
		value:
			'javascript:setDeleteCartCookie(); GlobalLoginJS.deleteLoginCookies();GlobalLoginJS.getFormElements(\'Header_GlobalLogin_GlobalLogon\');GlobalLoginJS.validateAndRedirect();return false;',
	},
	{ type: 'hidden', name: 'storeId', value: '10151' },
	{ type: 'hidden', name: 'catalogId', value: '10551' },
	{
		type: 'hidden',
		name: 'reLogonURL',
		value: 'LogonForm?langId=-1&storeId=10151&catalogId=10551',
	},
	{ type: 'hidden', name: 'myAcctMain', value: '' },
	{ type: 'hidden', name: 'fromOrderId', value: '*' },
	{ type: 'hidden', name: 'toOrderId', value: '.' },
	{ type: 'hidden', name: 'deleteIfEmpty', value: '*' },
	{ type: 'hidden', name: 'continue', value: '1' },
	{ type: 'hidden', name: 'createIfEmpty', value: '1' },
	{ type: 'hidden', name: 'calculationUsageId', value: '-1' },
	{ type: 'hidden', name: 'updatePrices', value: '0' },
	{ type: 'hidden', name: 'errorViewName', value: 'AjaxOrderItemDisplayView' },
	{ type: 'hidden', name: 'previousPage', value: '' },
	{ type: 'hidden', name: 'returnPage', value: '' },
	{ type: 'hidden', name: 'logonIdCopy', value: '' },
	{ type: 'text', name: 'logonId', value: '' },
	{ type: 'password', name: 'logonPassword', value: '' },
	{ type: 'checkbox', name: '', value: 'on' },
	{ type: 'hidden', name: 'rememberMe', value: 'false' },
	{ type: 'hidden', name: 'oktaFlow', value: true },
];

export const FORMDATAORDER = [
	{ type: 'name', elementType: 'formAttribute', value: 'logonForm' },
	{ type: 'id', elementType: 'formAttribute', value: 'okta_logonForm' },
	{ type: 'method', elementType: 'formAttribute', value: 'post' },
	{ type: 'action', elementType: 'formAttribute', value: '/OktaLogon' },
	{ type: 'onsubmit', elementType: 'formAttribute', value: '' },
	{ type: 'class', elementType: 'formAttribute', value: 'hidden' },
	{
		type: 'submit',
		name: 'okta_login_button',
		id: 'okta_login_button',
		elementType: 'button',
		value: 'Login',
	},
	{ type: 'hidden', name: 'action', value: 'login' },
	{ type: 'hidden', name: 'storeId', value: '10151' },
	{ type: 'hidden', name: 'langId', value: '-1' },
	{ type: 'hidden', name: 'catalogId', value: '10551' },
	{ type: 'hidden', name: 'fromOrderId', value: '*' },
	{ type: 'hidden', name: 'toOrderId', value: '.' },
	{ type: 'hidden', name: 'deleteIfEmpty', value: '*' },
	{ type: 'hidden', name: 'continue', value: '1' },
	{ type: 'hidden', name: 'createIfEmpty', value: '1' },
	{ type: 'hidden', name: 'calculationUsageId', value: '-1' },
	{ type: 'hidden', name: 'updatePrices', value: '0' },
	{ type: 'hidden', name: 'errorViewName', value: 'TrackOrderStatus' },
	{ type: 'hidden', name: 'reLogonURL', value: 'TrackOrderStatus' },
	{ type: 'hidden', name: 'logonIdCopy', value: '' },
	{ type: 'text', name: 'logonId', value: '' },
	{ type: 'password', name: 'logonPassword', value: '' },
	{ type: 'checkbox', name: '', value: 'on' },
	{ type: 'checkbox', name: 'rememberMe', value: 'true' },
	{ type: 'hidden', name: 'oktaFlow', value: true },
];

export const FORMDATACHECKOUT = [
	{
		type: 'name',
		elementType: 'formAttribute',
		value: 'logonForm',
	},
	{
		type: 'data-baseform',
		elementType: 'formAttribute',
		value: 'jjkuserlogonform',
	},
	{
		type: 'id',
		elementType: 'formAttribute',
		value: 'okta_logonForm',
	},
	{
		type: 'method',
		elementType: 'formAttribute',
		value: 'post',
	},
	{
		type: 'action',
		elementType: 'formAttribute',
		value: '/OktaBillShipLogon',
	},
	{
		type: 'class',
		elementType: 'formAttribute',
		value: 'hidden',
	},
	{
		type: 'submit',
		name: 'okta_login_button',
		id: 'okta_login_button',
		elementType: 'button',
		value: 'Login',
	},
	{
		type: 'hidden',
		name: 'callingPage',
		value: 'LoginBillShipForm',
	},
	{
		type: 'hidden',
		name: 'storeId',
		value: '10151',
	},
	{
		type: 'hidden',
		name: 'langId',
		value: '-1',
	},
	{
		type: 'hidden',
		name: 'catalogId',
		value: '10551',
	},
	{
		type: 'hidden',
		name: 'reLogonURL',
		value: 'LoginCheckoutFormView',
	},
	{
		type: 'hidden',
		name: 'includingPage',
		value: 'LoginCheckoutFormView',
	},
	{
		type: 'hidden',
		name: 'logonIdCopy',
		value: '',
	},
	{
		type: 'text',
		name: 'logonId',
		value: '',
	},
	{
		type: 'password',
		name: 'logonPassword',
		value: '',
	},
	{
		type: 'checkbox',
		name: '',
		value: 'on',
	},
	{
		type: 'checkbox',
		name: 'rememberMe',
		value: 'true',
	},
	{
		type: 'hidden',
		name: 'oktaFlow',
		value: true,
	},
];

export const CHECKOUTURIVALUE = 'OrderItemMove?storeId=10151&catalogId=10551&langId=-1&userType=G&userState=&userId=$USERIDVAL&URL=OrderCalculate%3fURL%3dLoginBillShipForm&page=account&fromOrderId=*&toOrderId=.&deleteIfEmpty=*&continue=1&createIfEmpty=1&calculationUsageId=-1&updatePrices=1&errorViewName=TopCategoriesDisplayView&reactURL=checkout-shipping';

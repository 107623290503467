import { ComIbmCommerceRestRedirectRedirectResponse } from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Redirect<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Api for retrieving url redirects
	 *
	 * @tags redirect
	 * @name JjkredirectDetail
	 * @summary redirect urls
	 * @request GET:/store/{storeId}/jjkredirect/{url}
	 * @secure
	 * @response `200` `ComIbmCommerceRestRedirectRedirectResponse` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	jjkredirectDetail = (url: string, storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['jjkredirectDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkredirect/${url}`,
				params: _params,
				methodName: 'jjkredirectDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestRedirectRedirectResponse, void>({
			path: `/store/${storeId}/jjkredirect/${url}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeOptions } from '@mui/material/styles';

export const dimensions: ThemeOptions = {
	dimensions: {
		contentSpacing: {
			xs: 2,
			sm: 2,
			md: 3,
			lg: 3,
		},
	},
};

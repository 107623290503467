import { SxProps, Theme } from '@mui/material';

export const cartContainerSX: SxProps<Theme> = {
	padding: 2,
	minWidth: '20vw',
	maxWidth: '375px',
	backgroundColor: 'background.lightgray',
	height: '100%',
	overflow: 'auto',
};

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ThemeOptions } from '@mui/material/styles';

export const CssBaseline: ThemeOptions = {
	components: {
		MuiCssBaseline: {
			styleOverrides: (theme) => `
			${theme.fonts
				.map(
					({ family, style, display, weight, src, unicodeRange }) => `
						@font-face {
							font-family: ${family};
							font-style: ${style};
							font-display: ${display};
							font-weight: ${weight};
							src: ${src};
							unicodeRange: ${unicodeRange};
						}
						`
				)
				.join(``)}
            html, body, #__next { max-width: 100vw; min-height: 100vh; display: flex; flex: 1; }
			#__next {flex-direction: column; }
			#__next > main {flex: 1; overflow-x:hidden;}
			main {margin-top: 24px;}
			img {
				max-width: 100%;
			}
			hr {
				width: 100%;
				color: #D8DBDD;
				border: 0;
				border-top: 1px solid #D8DBDD;
			}
			html {
				scroll-behavior: smooth;
			}
			h1{
				padding-bottom:1rem
			}
			/* p {
				font-size: 16px;
			} */
			h4 {
				font-size: 20px;
			}
		
			@media (max-width: 960px) {
				main {
					overflow:hidden
				}
			  }
            `,
		},
	},
};

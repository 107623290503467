/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { User } from '@/data/User';
import { getAccessVideoPage } from '@/data/containers/AccessVideoPage';
import { getAccountSettingsPage } from '@/data/containers/AccountSettingsPage';
import { getCoBrandCategoryDetailPage } from '@/data/containers/CoBrandCategoryDetailPage';
import { getEmailPreferencesPage } from '@/data/containers/EmailPreferencesPage';
import { getNewCustomerServicePage } from '@/data/containers/NewCustomerServicePage';
import { getNonPdpLeadGenerationPage } from '@/data/containers/NonPdpLeadGenerationPage';
import { getOrderStatusPage } from '@/data/containers/OrderStatusPage';
import { getReorderComp } from '@/data/containers/ReorderCompPage';
import { getReorderDetailsPage } from '@/data/containers/ReorderDetailsPage';
import { getReviewPage } from '@/data/containers/ReviewPage';
import { getSafetyDataSheetSearchPage } from '@/data/containers/SafetyDataSheetSearchPage';
import { RouteProtection } from '@/data/containers/manifest';
import { IncomingContent } from '@/data/types/IncomingContent';
import { Layout } from '@/data/types/Layout';
import { validateProtectedRoute } from '@/data/utils/validateProtectedRoute';
import { TranslationTable } from 'integration/generated/translations';

import { getAutoRenewFormPage } from '@/data/containers/AutoRenewFormPage';
import { getCheckOutPaymentPage } from '@/data/containers/CheckOutPayment';
import { getCheckOutReviewPage } from '@/data/containers/CheckOutReview';
import { getCheckOutShippingPage } from '@/data/containers/CheckOutShipping';
import { getFlipPlacardFinderPage } from '@/data/containers/FlipPlacardFinderPage';
import { getLoginPage } from '@/data/containers/LoginPage';
import { getNewsArticlePage } from '@/data/containers/NewsArticlePage';
import { getSecuritySealsFinderPage } from '@/data/containers/SecuritySealsFinder';
import { getTopicIndexPage } from '@/data/containers/TopicIndex';
import { getTopicIndexContentHeadlinePage } from '@/data/containers/TopicIndexContentHeadline';
import { getTopicIndexHeadlinePage } from '@/data/containers/TopicIndexHeadline';
import { Order } from '@/data/types/Order';

const layoutManifest = {
	ReviewPage: getReviewPage,
	NewCustomerServicePage: getNewCustomerServicePage,
	OrderStatusPage: getOrderStatusPage,
	AccountSettingsPage: getAccountSettingsPage,
	ReorderDetailsPage: getReorderDetailsPage,
	AccessVideoPage: getAccessVideoPage,
	CoBrandCategoryDetailPage: getCoBrandCategoryDetailPage,
	NonPdpLeadGenerationPage: getNonPdpLeadGenerationPage,
	EmailPreferencesPage: getEmailPreferencesPage,
	SafetyDataSheetSearchPage: getSafetyDataSheetSearchPage,
	ReorderCompPage: getReorderComp,
	NewsArticlePage: getNewsArticlePage,
	CheckOutShippingPage: getCheckOutShippingPage,
	CheckOutPaymentPage: getCheckOutPaymentPage,
	CheckOutReviewPage: getCheckOutReviewPage,
	TopicIndexPage: getTopicIndexPage,
	SecuritySealsFinderPage: getSecuritySealsFinderPage,
	FlipPlacardFinderPage: getFlipPlacardFinderPage,
	TopicIndexHeadlinePage: getTopicIndexHeadlinePage,
	TopicIndexContentHeadlinePage: getTopicIndexContentHeadlinePage,
	SupportLoginPage: getLoginPage,
	AutoRenewFormPage: getAutoRenewFormPage,
};

export const dataContainerManifestCustom: {
	[key: string]: (props: IncomingContent) => Layout;
} = layoutManifest;

type LayoutKeys = keyof typeof layoutManifest;
type LocalRoutes = TranslationTable['Routes'];

/**
 * Layout relationships with translation route name as the key and
 * the layoutManifest key as the value.
 */
export const dataRouteManifestCustom: Record<string, LayoutKeys> = {
	Review: 'ReviewPage',
	NewCustomerService: 'NewCustomerServicePage',
	EmailPreferences: 'EmailPreferencesPage',
	OrderStatus: 'OrderStatusPage',
	AccountSettings: 'AccountSettingsPage',
	Reorder: 'ReorderDetailsPage',
	AccessVideo: 'AccessVideoPage',
	CoBrandCategoryDetail: 'CoBrandCategoryDetailPage',
	NonPdpLeadGeneration: 'NonPdpLeadGenerationPage',
	SafetyDataSheetSearch: 'SafetyDataSheetSearchPage',
	ReorderComp: 'ReorderCompPage',
	NewsArticle: 'NewsArticlePage',
	CheckOutShipping: 'CheckOutShippingPage',
	CheckOutPayment: 'CheckOutPaymentPage',
	CheckOutReview: 'CheckOutReviewPage',
	TopicIndex: 'TopicIndexPage',
	SecuritySealsFinder: 'SecuritySealsFinderPage',
	FlipPlacardFinder: 'FlipPlacardFinderPage',
	TopicIndexHeadline: 'TopicIndexHeadlinePage',
	TopicIndexContentHeadline: 'TopicIndexContentHeadlinePage',
	SupportLogin: 'SupportLoginPage',
	AutoRenewForm: 'AutoRenewFormPage',
};

/**
 * Map of routes that are dependent on the user status for conditional
 * redirection or other protection. The method return dictates the
 * behavior:
 * 	- true: allow access
 * 	- false: do not load the route
 * 	- string: redirect to the route named in the string
 */
export const dataRouteProtectionCustom: Partial<
	Record<keyof LocalRoutes, (user: Partial<User>, cart?: boolean | Order) => RouteProtection>
> = {
	AccountSettings: (user) => validateProtectedRoute({ user }, 'login'),
	Reorder: (user) => validateProtectedRoute({ user }, 'login'),
	CheckOutReview: (_user, cart) => validateProtectedRoute({ cart }, 'cart'),
	CheckOutShipping: (_user, cart) => validateProtectedRoute({ cart }, 'cart'),
	CheckOutPayment: (_user, cart) => validateProtectedRoute({ cart }, 'cart'),
};

export const dataRouteProtectionFlexFlowMapCustom: Partial<Record<keyof LocalRoutes, string>> = {};

export const notCDNCacheableRouteCustom: Partial<Record<keyof LocalRoutes, boolean>> = {};

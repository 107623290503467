import { ThemeOptions } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { Box } from '@mui/material';

const iconStyle = {
    '& svg': {
        color: 'secondary.lightgrey',
        width: '14px',
    },
};

export const Autocomplete: ThemeOptions = {
    components: {
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <Box sx={iconStyle}><FontAwesomeIcon icon={faAngleDown} /></Box>,
            },
            styleOverrides: {
				root: getStyleOverrides({
					styles: {
						'& .MuiAutocomplete-popupIndicator': {
                            marginRight: '4px',
                        },
                        '& .MuiAutocomplete-popupIndicatorOpen': {
                            marginTop: '-5px',
                        },
					},
				}),
			},
        },
    },
};

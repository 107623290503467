import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class V2UrlResource<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags V2-Url-Resource
	 * @name GetV2CategoryResources1
	 * @summary Gets Response for V2.0 API Seo url data
	 * @request GET:/api/v2/urls
	 * @response `200` `string` default response
	 */
	getV2CategoryResources1 = (
		query: {
			/** @format int32 */
			storeId: number;
			identifier?: string[];
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['getV2CategoryResources1'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/api/v2/urls`,
				params: _params,
				query: query,
				methodName: 'getV2CategoryResources1',
			};
			logger.trace(traceData);
		}
		return this.http.request<string, any>({
			path: `/api/v2/urls`,
			method: 'GET',
			query: query,
			format: params.format ?? 'json',
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}

/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useLocalization } from '@/data/Localization';
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	SxProps,
	Theme,
} from '@mui/material';
import { FC, useId, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleExclamation } from '@fortawesome/sharp-light-svg-icons';
import { palette } from '@/styles/JJKeller/palette';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';

type Props = {
	label: string;
	name: string;
	value?: string;
	disabled?: boolean;
	placeholder?: string;
	inputProps?: object;
	required?: boolean;
	sx?: SxProps<Theme>;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	error?: boolean;
	autoFocus?: boolean;
	helperText?: string;
	passwordMatched?: boolean;
	shouldShowPassword?: boolean
};

export const PasswordInput: FC<Props> = ({
	label,
	name,
	value,
	disabled,
	placeholder,
	inputProps,
	required,
	sx,
	onChange,
	error = false,
	autoFocus = false,
	helperText = '',
	passwordMatched = false,
	shouldShowPassword = true,
}) => {
	const id = useId();
	const [showPassword, setShowPassword] = useState<boolean>(shouldShowPassword);
	const passwordInputNLS = useLocalization('PasswordInput');

	return (
		<FormControl variant="outlined" sx={sx} fullWidth={true} error={error} required={required}>
			<InputLabel htmlFor={`${id}-adorned-password`}>{label}</InputLabel>
			{name === 'xcred_logonPasswordOld' ? (
				<OutlinedInput
					id={`${id}-adorned-password`}
					data-testid={`${id}-adorned-password`}
					name={name}
					type={showPassword ? 'text' : 'password'}
					value={value}
					autoFocus={autoFocus}
					onChange={onChange}
					inputProps={inputProps}
					disabled={disabled}
					autoComplete="off"
					placeholder={placeholder}
					error={error}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label={passwordInputNLS.toggleLabel.t()}
								onClick={() => setShowPassword(!showPassword)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
									event.preventDefault();
								}}
								edge="end"
							>
								{error ? (
									<FontAwesomeIcon fontSize="18px" icon={faCircleExclamation} color="#CA251F" />
								) : showPassword ? (
									<FontAwesomeIcon fontSize="18px" icon={faEye} />
								) : (
									<FontAwesomeIcon fontSize="18px" icon={faEyeSlash} />
								)}
							</IconButton>
						</InputAdornment>
					}
					label={label}
				/>
			) : (
				<OutlinedInput
					id={`${id}-adorned-password`}
					data-testid={`${id}-adorned-password`}
					name={name}
					type={showPassword ? 'password' : 'text'}
					value={value}
					autoFocus={autoFocus}
					onChange={onChange}
					inputProps={inputProps}
					disabled={disabled}
					autoComplete="off"
					placeholder={placeholder}
					error={error}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label={passwordInputNLS.toggleLabel.t()}
								onClick={() => setShowPassword(!showPassword)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
									event.preventDefault();
								}}
								edge="end"
							>
								{error ? (
									<FontAwesomeIcon
										fontSize="18px"
										icon={faCircleExclamation}
										color={palette.palette.text.error}
									/>
								) : passwordMatched ? (
									<FontAwesomeIcon
										fontSize="18px"
										icon={faCheckCircle}
										color={palette.palette.text.success}
									/>
								) : showPassword ? (
									<FontAwesomeIcon fontSize="18px" icon={faEyeSlash} />
								) : (
									<FontAwesomeIcon fontSize="18px" icon={faEye} />
								)}
							</IconButton>
						</InputAdornment>
					}
					label={label}
				/>
			)}
			{helperText ? (
				<FormHelperText
					sx={{
						color: passwordMatched ? palette.palette.text.success : palette.palette.text.error,
					}}
				>
					{helperText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
};

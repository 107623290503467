import { Layout } from '@/data/types/Layout';

export const getEmailPreferencesPage = (): Layout => ({
	name: 'AsideMobileColumnReverse',
	slots: {
		header: [{ name: 'Header', id: 'header' }],

		aside: [{ name: 'NewCustomerServiceSidebar', id: 'side-bar' }],

		second: [{ name: 'EmailPreferences', id: 'email-preferences' }],

		footer: [{ name: 'Footer', id: 'footer' }],
	},
});

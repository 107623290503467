/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */
export const ADDRESS_FIELD_LENGTH = {
	phone1: 32,
	fax1: 32,
	phone2: 32,
	fax2: 32,
	mobilePhone1: 32,
	zipCode: 40,
	personTitle: 50,
	field3: 64,
	field1: 64,
	field2: 64,
	logonId: 128,
	orgName: 128,
	lastName: 128,
	firstName: 128,
	middleName: 128,
	businessTitle: 128,
	address2: 128,
	address3: 128,
	city: 128,
	state: 128,
	country: 128,
	nickName: 254,
	address1: 256,
	email1: 256,
	email2: 256,
};

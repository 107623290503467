import {
	ComIbmCommerceSapOrderHandler,
	ComIbmCommerceSapOrderHistoryHandler,
} from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class SapOrder<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description get SAP product by its Id
	 *
	 * @tags sap-order
	 * @name SapGetOrderHistoryDetails
	 * @summary Get SAP product by its Id
	 * @request GET:/store/{storeId}/jjkOrderHistory/getOrderHistoryDetails
	 * @secure
	 * @response `200` `ComIbmCommerceSapOrderHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	sapGetOrderHistoryDetails = (
		storeId: string,
		query: {
			/** sap order identifier */
			sapOrderId: string;
			/** commerce order identifier */
			orderId: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['sapGetOrderHistoryDetails'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkOrderHistory/getOrderHistoryDetails`,
				params: _params,
				query: query,
				methodName: 'sapGetOrderHistoryDetails',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceSapOrderHandler, void>({
			path: `/store/${storeId}/jjkOrderHistory/getOrderHistoryDetails`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description get SAP orders
	 *
	 * @tags sap-order
	 * @name SapGetOrderHistoryList
	 * @summary Get SAP orders history
	 * @request GET:/store/{storeId}/jjkOrderHistory/orderHistoryList
	 * @secure
	 * @response `200` `ComIbmCommerceSapOrderHistoryHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	sapGetOrderHistoryList = (
		storeId: string,
		query?: {
			/** range of orders */
			range?: string;
			/** startDate of custom range */
			startDate?: string | null;
			/** endDate of custom range */
			endDate?: string | null;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['sapGetOrderHistoryList'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkOrderHistory/orderHistoryList`,
				params: _params,
				query: query,
				methodName: 'sapGetOrderHistoryList',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceSapOrderHistoryHandler, void>({
			path: `/store/${storeId}/jjkOrderHistory/orderHistoryList`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}

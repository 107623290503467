/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import ProductListingPage from '@/data/containers/default/ProductListingPage';
import SearchPage from '@/data/containers/default/SearchPage';
import { DefaultContainerLayout } from '@/data/types/ContainerLayout';

export const defaultContainerLayoutManifestCustom: {
	[pageType: string]: DefaultContainerLayout;
} = {
	ProductListPage: ProductListingPage,
	SearchPage,
};

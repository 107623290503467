/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const snackBarSX: SxProps<Theme> = {
	maxWidth: '100%',
	p: 2,
};

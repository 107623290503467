/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2024.
 */
import { StackProps, SxProps } from '@mui/material';

export const renderContentStackSX: SxProps = {
	// customize
};

export const renderContentStack: StackProps = {
	spacing: 1,
	// customize
};

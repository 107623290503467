/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ItemWrap } from '@/components/layouts/ItemWrap';
import { LayoutSlot } from '@/components/layouts/LayoutSlot';
import { layoutMainSX } from '@/components/layouts/styles/main';
import { Layout } from '@/data/types/Layout';
import { Stack, Box, Grid } from '@mui/material';
import { FC } from 'react';

export const TripleStackPdp: FC<{ layout: Layout }> = ({ layout }) =>
	layout ? (
		<>
			<LayoutSlot slot={layout.slots.header} />
			<Box sx={{ paddingTop: 0 }}>
				<Stack
					component="main"
					alignItems="center"
					sx={layoutMainSX(layout.slots.first?.length || 0)}
				>
					<LayoutSlot slot={layout.slots.first} ItemWrap={ItemWrap} />
					<LayoutSlot slot={layout.slots.second} ItemWrap={ItemWrap} />
					<LayoutSlot slot={layout.slots.third} ItemWrap={ItemWrap} />
					<Grid container marginTop="0.5rem">
						<LayoutSlot slot={layout.slots.footer} />
					</Grid>
				</Stack>
			</Box>
		</>
	) : null;

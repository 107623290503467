/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { ThemeOptions } from '@mui/material/styles';

export type customTypography = ThemeOptions & {
	typography: {
		h7: React.CSSProperties;
		h8: React.CSSProperties;
		h9: React.CSSProperties;
		h10: React.CSSProperties;
		body3: React.CSSProperties;
		body3Light: React.CSSProperties;
		body3Bold: React.CSSProperties;
		body2Bold?: React.CSSProperties;
		body4: React.CSSProperties;
		body4Bold: React.CSSProperties;
		body5: React.CSSProperties;
		small: React.CSSProperties;
		captionBold: React.CSSProperties;
		largeBody: React.CSSProperties;
		display1: React.CSSProperties;
		display2: React.CSSProperties;
	};
};
export const typography: customTypography = {
	typography: {
		htmlFontSize: 16,
		fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
		h1: {
			fontSize: 38,
			fontWeight: 700,
		},
		h2: {
			fontSize: 30,
			fontWeight: 600,
		},
		h3: {
			fontSize: 24,
			fontWeight: 600,
		},
		h4: {
			fontSize: 20,
			fontWeight: 600,
		},
		h5: {
			fontSize: 18,
			fontWeight: 600,
		},
		h6: {
			fontSize: 16,
			fontWeight: 600,
		},
		h7: {
			fontSize: 14,
			fontWeight: 600,
		},
		h8: {
			fontSize: 18,
			fontWeight: 600,
		},
		h9: {
			fontSize: 24,
			fontWeight: 600,
		},
		h10: {
			fontSize: 38,
			fontWeight: 600,
		},
		subtitle1: {
			fontSize: 18,
			fontWeight: 500,
		},
		subtitle2: {
			fontSize: 18,
			fontWeight: 600,
		},

		body1: {
			// temp withheld until client response
			fontSize: 16,
			fontWeight: 400,
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
		},
		body2Bold: {
			fontSize: 14,
			fontWeight: 600,
		},
		body3: {
			fontSize: 16,
			fontWeight: 500,
		},
		body3Light: {
			fontSize: 16,
			fontWeight: 400,
		},
		body3Bold: {
			fontSize: 16,
			fontWeight: 600,
		},
		body4: {
			fontSize: 14,
			fontWeight: 400,
			color: '#5c6266',
		},
		body4Bold: {
			fontSize: 14,
			fontWeight: 600,
			color: '#43484d',
		},
		body5: {
			fontSize: 14,
			fontWeight: 400,
			color: '#757a7e',
		},
		largeBody: {
			fontSize: 24,
			fontWeight: 400,
		},
		small: {
			fontSize: 11,
			fontWeight: 400,
		},
		button: {
			fontSize: 15,
			fontWeight: 400,
			textTransform: 'none',
		},
		captionBold: {
			fontSize: 12,
			fontWeight: 600,
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
		},
		overline: {
			fontSize: 12,
			textTransform: 'uppercase',
			fontWeight: 600,
			letterSpacing: 1,
		},

		display1: {
			fontSize: '52px' /* = 39pt */,
			/* fontStyle: 'normal', */
			fontWeight: 600,
			lineHeight: '125%',
		},
		display2: {
			fontSize: '46px' /* = 35pt */,
			/* fontStyle: 'normal', */
			fontWeight: 600,
			lineHeight: '125%',
		},
	},

	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					// Map the new variant to render a <p> by default
					body3: 'p',
					h8: 'h8',
				},
			},
		},
	},
};

declare module '@mui/material/styles' {
	interface TypographyVariants {
		h7: React.CSSProperties;
		h8: React.CSSProperties;
		h9: React.CSSProperties;
		h10: React.CSSProperties;
		body3: React.CSSProperties;
		body3Bold: React.CSSProperties;
		body3Light: React.CSSProperties;
		body2Bold: React.CSSProperties;
		body4: React.CSSProperties;
		body4Bold: React.CSSProperties;
		largeBody: React.CSSProperties;
		captionBold: React.CSSProperties;
		body5: React.CSSProperties;
		small: React.CSSProperties;
		display1: React.CSSProperties;
		display2: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		h7?: React.CSSProperties;
		h8?: React.CSSProperties;
		h9?: React.CSSProperties;
		h10?: React.CSSProperties;
		body3?: React.CSSProperties;
		body3Light?: React.CSSProperties;
		body3Bold?: React.CSSProperties;
		body2Bold?: React.CSSProperties;
		body4?: React.CSSProperties;
		body4Bold?: React.CSSProperties;
		captionBold?: React.CSSProperties;
		largeBody?: React.CSSProperties;
		body5?: React.CSSProperties;
		small?: React.CSSProperties;
		display1?: React.CSSProperties;
		display2?: React.CSSProperties;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body3Bold: true;
		body3: true;
		body3Light: true;
		body2Bold: true;
		body4: true;
		body4Bold: true;
		body5: true;
		largeBody: true;
		small: true;
		h7: true;
		h8: true;
		h9: true;
		h10: true;
		captionBold: true;
		display1: true;
		display2: true;
	}
}

import { useEffect, useMemo, useState } from 'react';
import { ESpotContainerType, useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import { processMarketingContent } from '@/utils/processMarketingContent';
import { ADVANTAGE_HEADER_LOGO, ADVANTAGE_HEADER_SUBMENU } from '@/data/constants/marketing';

export const useAdvantageEspots = () => {
	const [isAdvProduct, setIsAdvProduct] = useState(false);
	const dataMap = (contents?: ESpotContainerType) =>
		contents?.MarketingSpotData?.at(0)?.baseMarketingSpotActivityData?.map(processMarketingContent);

	const { data: _data_header_logo, mutate: mutateLogo } = useESpotDataFromName(ADVANTAGE_HEADER_LOGO);
	const { data: _data_header_subMenu, mutate: mutateSubMenu } = useESpotDataFromName(ADVANTAGE_HEADER_SUBMENU);

	const advantageEspotLogo = useMemo(() => dataMap(_data_header_logo), [_data_header_logo]);
	const advantageEspotSubMenu = useMemo(
		() => dataMap(_data_header_subMenu),
		[_data_header_subMenu]
	);

	let isUserAdvantage = false;
	if (advantageEspotLogo) {
		isUserAdvantage = true;
	}
	useEffect(() => {
		if (isAdvProduct) {
			mutateLogo();
			mutateSubMenu();
			setIsAdvProduct(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdvProduct]);

	return {
		advantageEspotLogo,
		advantageEspotSubMenu,
		isUserAdvantage,
		isAdvProduct,
		setIsAdvProduct,
	};
};

import {
	ComIbmCommerceRestEventsEventsByTagAndNumHandler,
	ComIbmCommerceRestEventsEventsByTypeHandler,
	ComIbmCommerceRestEventsTypesHandler,
} from './data-contracts';
import { HttpClient, RequestParams, TraceDataType } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class Events<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description Get all types
	 *
	 * @tags events
	 * @name EventMarketTypes
	 * @summary Get event and market types
	 * @request GET:/store/{storeId}/jjkevents/types
	 * @secure
	 * @response `200` `ComIbmCommerceRestEventsTypesHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	eventMarketTypes = (storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['eventMarketTypes'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkevents/types`,
				params: _params,
				methodName: 'eventMarketTypes',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestEventsTypesHandler, void>({
			path: `/store/${storeId}/jjkevents/types`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Get events by type
	 *
	 * @tags events
	 * @name EventsByType
	 * @summary Get events by type
	 * @request GET:/store/{storeId}/jjkevents/eventsByEventAndMarketTypes
	 * @secure
	 * @response `200` `ComIbmCommerceRestEventsEventsByTypeHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `401` `void` Not authenticated. The user session is not valid.
	 * @response `403` `void` The user is not authorized to perform the specified request.
	 * @response `404` `void` The specified resource could not be found.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	eventsByType = (
		storeId: string,
		query: {
			/** sap order identifier */
			eventType: string;
			/** sap order identifier */
			marketType: string;
		},
		params: RequestParams = {}
	) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (loggerCan('trace') && (!this.http.traceDetails || this.http.traceDetails['eventsByType'])) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkevents/eventsByEventAndMarketTypes`,
				params: _params,
				query: query,
				methodName: 'eventsByType',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestEventsEventsByTypeHandler, void>({
			path: `/store/${storeId}/jjkevents/eventsByEventAndMarketTypes`,
			method: 'GET',
			query: query,
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description Events
	 *
	 * @tags events
	 * @name JjkeventsEventsByTagDetail
	 * @summary Events
	 * @request GET:/store/{storeId}/jjkevents/eventsByTag/{tag}
	 * @secure
	 * @response `200` `ComIbmCommerceRestEventsEventsByTagAndNumHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	jjkeventsEventsByTagDetail = (tag: string, storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['jjkeventsEventsByTagDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkevents/eventsByTag/${tag}`,
				params: _params,
				methodName: 'jjkeventsEventsByTagDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestEventsEventsByTagAndNumHandler, void>({
			path: `/store/${storeId}/jjkevents/eventsByTag/${tag}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
	/**
	 * @description News & Events
	 *
	 * @tags events
	 * @name JjkeventsEventsSimpleDetail
	 * @summary News Events
	 * @request GET:/store/{storeId}/jjkevents/eventsSimple/{number}
	 * @secure
	 * @response `200` `ComIbmCommerceRestEventsEventsByTagAndNumHandler` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	jjkeventsEventsSimpleDetail = (number: string, storeId: string, params: RequestParams = {}) => {
		const { _requestId: requestId, skipErrorLogging, ..._params } = params as any;
		let traceData: TraceDataType | undefined;

		if (
			loggerCan('trace') &&
			(!this.http.traceDetails || this.http.traceDetails['jjkeventsEventsSimpleDetail'])
		) {
			traceData = {
				requestId,
				msg: 'API request',
				url: `${this.http.baseUrl}/store/${storeId}/jjkevents/eventsSimple/${number}`,
				params: _params,
				methodName: 'jjkeventsEventsSimpleDetail',
			};
			logger.trace(traceData);
		}
		return this.http.request<ComIbmCommerceRestEventsEventsByTagAndNumHandler, void>({
			path: `/store/${storeId}/jjkevents/eventsSimple/${number}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			storeId,
			traceData,
			skipErrorLogging,
			..._params,
		});
	};
}
